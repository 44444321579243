<template>
  <div id="transport-routes" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <b-table :data="transportRoutes" :paginated="isPaginated" hoverable>
        <b-table-column field="#" label="#" width="40" numeric v-slot="props">
          {{ transportRoutes.indexOf(props.row) + 1 }}
        </b-table-column>

        <b-table-column label="Name" v-slot="props">{{
          props.row.name
        }}</b-table-column>

        <b-table-column label="Amount" v-slot="props">{{
          props.row.amount
        }}</b-table-column>

        <b-table-column label="Actions" width="160" v-slot="props">
          <action-buttons
            @initialize-info="initializeInfo(props.row)"
            @initialize-update="initializeUpdate(props.row)"
            @initialize-remove="initializeRemove(props.row)"
          />
        </b-table-column>
      </b-table>
    </template>
    <!-- Delete -->
    <app-modal
      id="remove-transport-route"
      context="remove"
      @remove="remove"
      :show-modal.sync="isModalOpen"
    />
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { crudNotification } from '../../assets/js/notification'

export default {
  name: 'TransportRoutes',
  data() {
    return {
      schoolId: null,
      pageTitle: 'Transport Routes',
      transportRoutes: [],
      isPaginated: true,
      id: null,
    }
  },
  methods: {
    initializeInfo(transport_route) {
      // this.$router.push(
      //   `/school/${this.schoolId}/transport_route_info/${transport_route.id}`
      // )
    },
    initializeUpdate(transport_route) {
      this.$router.push(
        `/school/${this.schoolId}/transport_route/${transport_route.id}`
      )
    },
    initializeRemove(transport_route) {
      this.id = parseInt(transport_route.id)
      this.openModal()
    },
    remove() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation DeleteTransportRoute($id: Int!) {
              deleteTransportRoute(input: { id: $id }) {
                transportRoute {
                  id
                }
                errors
              }
            }
          `,
          variables: {
            id: this.id,
          },
        })
        .then((response) => {
          crudNotification(
            response.data.deleteTransportRoute.errors,
            'Successfully deleted.'
          ).then(() => {
            this.closeModal()
            this.$apollo.queries.transportRoutes.refetch()
          })
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [
      {
        name: 'Fee Management',
        route: `/school/${this.schoolId}/fee_management`,
      },
      {
        name: 'New',
        route: `/school/${this.$route.params.school_id}/transport_route/new`,
      },
    ])

    this.$apollo.addSmartQuery('transportRoutes', {
      query: gql`
        query TransportRoutesQuery($schoolId: ID!) {
          transportRoutes(schoolId: $schoolId) {
            id
            name
            amount
          }
        }
      `,
      variables: {
        schoolId: this.schoolId,
      },
    })

    this.$apollo.queries.transportRoutes.refetch()
  },
}
</script>
